import { render, staticRenderFns } from "./ZbNotificationsDetail.html?vue&type=template&id=52b97bbf&"
import script from "./ZbNotificationsDetail.js?vue&type=script&lang=js&"
export * from "./ZbNotificationsDetail.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports