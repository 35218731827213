import moment from 'moment'
import Chart from 'chart.js'
import { ServiceFactory } from '@/services/ServiceFactory'
// import ZbConditionModal from './special-condition-modal'
// const _specialConditionsService = ServiceFactory.get('SpecialConditionsService')
// const _barsService = ServiceFactory.get('BarsService')
// const _urlCounterService = ServiceFactory.get('UrlCounterService')
const _notificationService = ServiceFactory.get('NotificationsService')
export default {
  // components: {
  //   ZbConditionModal
  // },
  data () {
    return {
      // settings: [],
      // conditions: [],
      // loading: false,
      // currentItem: null,
      // barId: ''
      // secMeasureId: {
      //   zbSecurityMeasureId: ''
      // }
      url: '',
      headers: [
        {
          text: 'Service From',
          align: 'start',
          sortable: false,
          value: 'serviceFrom'
        },
        { text: 'Total Android', align: 'center', sortable: false, value: 'android' },
        { text: 'Total iOS', align: 'center', sortable: false, value: 'ios' },
        { text: 'Total otros', align: 'center', sortable: false, value: 'others' },
        { text: 'Click totales', align: 'center', sortable: false, value: 'total' },
        { text: '', value: 'data-table-expand' }
        // { text: 'Opciones', value: 'options' }
      ],
      items: [],
      loading: false,
      loading2: false,
      menu2: false,
      dates: [],
      chartData: {},
      chart: [],
      pieChart: {},
      expanded: [],
      singleExpand: true,
      chartService: null,
      chServData: {},
      pieChartServ: null,
      pieChServData: {},
      loadingChart: false,
      loadingPicker: false,
      total: null,
      historic: null,
      chartAmp: false,
      pAndroid: 0.0,
      pIos: 0.0,
      pOther: 0.0,
      successful: 0,
      converted: 0,
      errored: 0,
      failed: 0,
      totalNotif: 0,
      ios: 0,
      android: 0
    }
  },
  // watch: {
  //   expanded: async function () {
  //     if (this.expanded.length > 0) {
  //       this.loadingChart = true
  //       if (this.chartService) {
  //         this.chartService.destroy()
  //       }
  //       if (this.pieChartServ) {
  //         this.pieChartServ.destroy()
  //       }
  //       // console.log(this.expanded)
  //       var servStatsResp = await _urlCounterService.servStats(this.dates[1], this.dates[0], this.url, this.expanded[0].serviceFrom)
  //       this.loadingChart = false
  //       // console.log(servStatsResp)
  //       setTimeout(() => {
  //         this.chServData = {
  //           labels: servStatsResp.dataset.labels,
  //           datasets: [
  //             {
  //               label: 'Clicks',
  //               data: servStatsResp.dataset.data,
  //               borderColor: '#ff5a5f'
  //               // backgroundColor: chartDatasetsBackground
  //             }
  //           ]
  //         }
  //         var chId = 'chart_' + this.expanded[0].serviceFrom
  //         var canvas = document.getElementById(chId).getContext('2d')
  //         this.chartService = new Chart(canvas, {
  //           type: 'line',
  //           data: this.chServData,
  //           options: {
  //             title: {
  //               display: true,
  //               text: 'Click por dia'
  //             },
  //             responsive: true,
  //             scales: {
  //               y: {
  //                 beginAtZero: true
  //               }
  //             },
  //             legend: { display: false }
  //           }
  //         })

  //         this.pieChServData = {
  //           labels: servStatsResp.os.labels,
  //           datasets: [
  //             {
  //               label: 'Pie',
  //               data: servStatsResp.os.data,
  //               backgroundColor: ['Green', 'Yellow', 'Orange']
  //             }
  //           ]
  //         }
  //         var chId2 = 'pie_' + this.expanded[0].serviceFrom
  //         var canvas2 = document.getElementById(chId2).getContext('2d')
  //         // var canvas2 = document.getElementById('chartPie').getContext('2d')
  //         this.pieChartServ = new Chart(canvas2, {
  //           type: 'pie',
  //           data: this.pieChServData,
  //           options: {
  //             title: {
  //               display: true,
  //               text: 'Clicks por S.O.'
  //             },
  //             responsive: true,
  //             legend: { display: false }
  //           }
  //         })
  //       }, 1000)
  //     } else {
  //       this.chartService = null
  //       this.pieChartServ = null
  //     }
  //   }
  // },
  mounted () {
    // this.indexSecurityMeasures()
    this.initialize()
  },
  methods: {
    // async urlCounter() {},
    async initialize () {
      var date1 = moment().format('YYYY-MM-DD')
      var date2 = moment().subtract(7, 'days').format('YYYY-MM-DD')
      this.dates = [date2, date1]
      // console.log(date1, date2)

      // this.currentItem = null
      if (this.$route.params.id) {
        console.log(this.$route.params.id)
        const notifId = this.$route.params.id
        this.loading = true
        try {
          this.successful = 0
          this.converted = 0
          this.errored = 0
          this.failed = 0
          this.totalNotif = 0
          this.ios = 0
          this.android = 0
          var servStatsResp = await _notificationService.singleNotificationStats(notifId)
          console.log(servStatsResp)
          this.loading = false
          if (servStatsResp.ios) {
            // console.log('IOS')
            this.successful = this.successful + servStatsResp.ios.successful
            this.converted = this.converted + servStatsResp.ios.converted
            this.failed = this.failed + servStatsResp.ios.failed
            this.errored = this.errored + servStatsResp.ios.errored
            this.ios = servStatsResp.ios.successful
            this.totalNotif = this.totalNotif + this.ios
          }
          if (servStatsResp.android) {
            // console.log('Android')
            this.successful = this.successful + servStatsResp.android.successful
            this.converted = this.converted + servStatsResp.android.converted
            this.failed = this.failed + servStatsResp.android.failed
            this.errored = this.errored + servStatsResp.android.errored
            this.android = servStatsResp.android.successful
            this.totalNotif = this.totalNotif + this.android
          }
          this.pieChart = {
            labels: ['Exitoso', 'Falló', 'Error'], // servStatsResp.dataset.labels,
            datasets: [
              {
                label: 'Perf',
                data: [this.successful, this.failed, this.errored], // servStatsResp.dataset.data,
                backgroundColor: ['Green', 'Orange', 'Red'],
                hoverBackgroundColor: ['#388E3C', '#F57C00', '#D32F2F']
                // backgroundColor: chartDatasetsBackground
              }
            ]
          }
          var canvas2 = document.getElementById('chartPieStats').getContext('2d')
          this.chart[1] = new Chart(canvas2, {
            type: 'pie',
            data: this.pieChart,
            options: {
              title: {
                display: true,
                text: 'Rendimiento'
              },
              responsive: true,
              legend: { display: false }
            }
          })
          this.pieChartOs = {
            labels: ['Android', 'iOS'], // servStatsResp.os.labels,
            datasets: [
              {
                label: 'OS',
                data: [this.android, this.ios], // servStatsResp.os.data,
                backgroundColor: ['Green', 'black'],
                hoverBackgroundColor: ['#388E3C', '#212121']
              }
            ]
          }
          var canvas3 = document.getElementById('chartPieOs').getContext('2d')
          this.chart[2] = new Chart(canvas3, {
            type: 'pie',
            data: this.pieChartOs,
            options: {
              title: {
                display: true,
                text: 'Sistema operativo'
              },
              responsive: true,
              legend: { display: false }
            }
          })
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      }

      // chart settings
      try {
        this.loading2 = true
        var statsResp = await _notificationService.notificationsStats(this.dates[1], this.dates[0])
        console.log(statsResp)
        this.loading2 = false
        // if (statsResp.total > 0) {
        //   this.pAndroid = Math.round((statsResp.os.data[0] / statsResp.total) * 10000) / 100
        //   this.pIos = Math.round((statsResp.os.data[1] / statsResp.total) * 10000) / 100
        //   this.pOther = Math.round((statsResp.os.data[2] / statsResp.total) * 10000) / 100
        // } else {
        //   this.pAndroid = 0
        //   this.pIos = 0
        //   this.pOther = 0
        // }
        // this.total = statsResp.total
        // this.historic = statsResp.historic
        this.chartData = {
          labels: statsResp.immediately.labels, // ['2022-06-01', '2022-06-02', '2022-06-03', '2022-06-04', '2022-06-05', '2022-06-06'], // statsResp.dataset.labels,
          datasets: [
            {
              label: 'Notificaciones',
              data: statsResp.immediately.data,
              borderColor: '#ff5a5f'
              // backgroundColor: '#ff5a5f'
            }
          ]
        }
        var canvas = document.getElementById('chart').getContext('2d')
        this.chart[0] = new Chart(canvas, {
          type: 'line',
          data: this.chartData,
          options: {
            title: {
              display: true,
              text: 'Notificaciones por dia'
            },
            responsive: true,
            scales: {
              y: {
                beginAtZero: true
              }
            },
            legend: { display: false }
          }
        })
      } catch (error) {
        console.log(error)
        this.loading2 = false
      }
    },
    async changeData () {
      console.log(this.dates)
      try {
        this.loadingPicker = true
        var statsResp = await _notificationService.notificationsStats(this.dates[1], this.dates[0])
        // this.total = statsResp.total
        // this.historic = statsResp.historic
        console.log(statsResp)
        this.chart[0].config.data.labels = statsResp.immediately.labels
        this.chart[0].config.data.datasets[0].data = statsResp.immediately.data
        this.chart[0].update()
        // this.chart[1].config.data.labels = statsResp.os.labels
        // this.chart[1].config.data.datasets[0].data = statsResp.os.data
        // this.chart[1].update()
        // if (statsResp.total > 0) {
        //   this.pAndroid = Math.round((statsResp.os.data[0] / statsResp.total) * 10000) / 100
        //   this.pIos = Math.round((statsResp.os.data[1] / statsResp.total) * 10000) / 100
        //   this.pOther = Math.round((statsResp.os.data[2] / statsResp.total) * 10000) / 100
        // } else {
        //   this.pAndroid = 0
        //   this.pIos = 0
        //   this.pOther = 0
        // }
        // if (this.chartService && this.pieChartServ) {
        //   var servStatsResp = await _urlCounterService.servStats(this.dates[1], this.dates[0], this.url, this.expanded[0].serviceFrom)
        //   // console.log(servStatsResp)
        //   this.chartService.config.data.labels = servStatsResp.dataset.labels
        //   this.chartService.config.data.datasets[0].data = servStatsResp.dataset.data
        //   this.chartService.update()
        //   this.pieChartServ.config.data.labels = servStatsResp.os.labels
        //   this.pieChartServ.config.data.datasets[0].data = servStatsResp.os.data
        //   this.pieChartServ.update()
        // }
        this.loadingPicker = false
      } catch (error) {
        console.log(error)
        this.loadingPicker = false
      }
    },
    // amplify () {
    //   var chCol = document.getElementById('chartCol')
    //   this.chartAmp = !this.chartAmp
    //   console.log(chCol, this.chartAmp)
    //   if (this.chartAmp) {
    //     chCol.className = 'col-12 text-center'
    //   } else {
    //     chCol.className = 'col-9 text-center'
    //   }
    // },
    goBack () {
      this.$router.push({ name: 'notifications' }).catch(() => {})
    }
  }
}
